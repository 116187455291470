import {
  FC,
  PropsWithChildren,
  ReactNode,
} from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Stack,
  StackProps,
  Typography
} from '@mui/joy';
import { TitledSectionTooltip } from './titled-section-tooltip.component';

export type TitledSectionProps = PropsWithChildren<{
  label: string;
  tooltip?: Maybe<string> | ReactNode;
  asAccordion?: boolean;
} & StackProps>;

export const TitledSection: FC<TitledSectionProps> = ({
  label,
  tooltip,
  asAccordion,
  children,
  ...props
}) => asAccordion ? (
  <Accordion defaultExpanded={false}>
    <AccordionSummary
      variant='plain'
      sx={({ palette }) => ({
        paddingBottom: 1,
        '.MuiAccordionSummary-button:hover': {
          backgroundColor: `rgba(0, 0, 0, 0) !important`
        },
        '.MuiAccordionSummary-indicator svg': {
          fontSize: 24,
          color: palette.common.white
        },
      })}>
      <Stack
        direction='row'
        alignItems='center'
        justifyContent='flex-start'
        columnGap={1}>
        <Typography
          sx={({ palette }) => ({
            fontSize: 14,
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: '20px',
            color: palette.common.white,
          })}>
          {label}
        </Typography>
        {tooltip && <TitledSectionTooltip title={tooltip} />}
      </Stack>
    </AccordionSummary>
    <AccordionDetails>
      {children}
    </AccordionDetails>
  </Accordion >
) : (
    <Stack
      component='aside'
      direction='column'
      width='100%'
      gap={2}
      {...props}>
      <Stack
        direction='row'
        alignItems='center'
        justifyContent='flex-start'
        columnGap={1}>
        <Typography
          sx={({ palette }) => ({
            fontSize: 14,
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: '20px',
            color: palette.common.white,
          })}>
          {label}
        </Typography>
        {tooltip && <TitledSectionTooltip title={tooltip} />}
      </Stack>
      {children}
    </Stack>
  );