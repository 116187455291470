import { FC, MouseEvent, ReactNode } from 'react';
import {
  Box,
  Modal,
  ModalDialog,
  ModalDialogProps,
  Stack,
} from '@mui/joy';
import {
  CloseIcon,
  IconButton,
} from '@shared/ui';
import {
  isDefined,
} from '@shared/lib';
import {
  FreePlayDisclaimer
} from './free-play-disclaimer.component';
import {
  PleaseConfirmMessage
} from './please-confirm-message.component';

export type PleaseConfirmYourIdentityModalProps = Pick<ModalDialogProps, 'sx'> & {
  open: boolean;
  titleSlot?: ReactNode;
  onClose(): void;
};

export const PleaseConfirmYourIdentityModal: FC<PleaseConfirmYourIdentityModalProps> = ({
  open,
  onClose,
  titleSlot,
  ...props
}) => {
  const handleClose = (
    event: MouseEvent<HTMLButtonElement>,
    reason: 'backdropClick' | 'escapeKeyDown' | 'closeClick'
  ): void => {
    event?.stopPropagation();
    event?.preventDefault();
    if (reason === 'closeClick') onClose?.();
  };
  return (
    <Modal
      open={open}
      onClose={handleClose}>
      <ModalDialog
        layout='center'
        sx={({ breakpoints, palette }) => ({
          maxWidth: 488,
          width: '100%',
          backgroundColor: palette.common[925],
          [breakpoints.down(600)]: {
            width: '90%',
          },
        })}
        {...props}>
        <Stack
          flexDirection='column'
          sx={({ breakpoints }) => ({
            width: '100%',
            height: '100%',
            padding: 3,
            [breakpoints.down(490)]: {
              paddingInline: 2,
              paddingTop: 2,
              paddingBottom: 4,
            },
          })}>
          <Stack
            direction='row'
            alignItems='center'
            justifyContent={
              isDefined(titleSlot)
                ? 'space-between'
                : 'flex-end'
            }
            sx={{
              width: '100%',
              marginBottom: 3,
            }}>
            {titleSlot}
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Stack>
          <Box
            sx={{
              width: '100%',
              marginBottom: 3,
            }}>
            <PleaseConfirmMessage />
          </Box>
          <Stack
            direction='column'
            sx={{ width: '100%', }}>
            <FreePlayDisclaimer />
          </Stack>
        </Stack>
      </ModalDialog>
    </Modal>
  );
};
