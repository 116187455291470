import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { pageLayoutSlice } from './slice';
import {
  type PageFooterNavigationItem,
  type LayoutState,
  type SidebarMenuItem,
  type StickyNavigation,
  type FollowUs,
} from './types';

type RootState = {
  [pageLayoutSlice.name]: LayoutState;
};

export const selectPageHeaderVariant1LogoImgSrc = (state: RootState): Url => (
  state[pageLayoutSlice.name].header?.logo?.variant1SizeImgSrc ?? ''
);

export const selectPageHeaderVariant2LogoImgSrc = (state: RootState): Url => (
  state[pageLayoutSlice.name].header?.logo?.variant2SizeImgSrc ?? ''
);

export const selectPageFooterVariant1LogoImgSrc = (state: RootState): string => (
  state[pageLayoutSlice.name].footer?.logo.variant1SizeImgSrc ?? ''
);

export const selectPageFooterPromotionLegalNote = (state: RootState): string => (
  state[pageLayoutSlice.name].footer?.promotionLegalNote ?? ''
);

export const selectPageFooterFollowUs = (state: RootState): Maybe<FollowUs> => (
  state[pageLayoutSlice.name].footer?.followUs
);

export const selectPageFooterNavigationItems = (state: RootState): Maybe<Array<PageFooterNavigationItem>> => (
  state[pageLayoutSlice.name].footer?.navigationItems
);

export const selectSidebarMenuLogo = (state: RootState): Url => (
  state[pageLayoutSlice.name]?.sidebarMenu?.logo.variant1SizeImgSrc ?? ''
);

export const selectSidebarMenuBanner = (state: RootState): Url => (
  state[pageLayoutSlice.name]?.sidebarMenu?.banner.variant1SizeImgSrc ?? ''
);

export const selectSidebarMenuItems = (state: RootState): Array<SidebarMenuItem> => (
  state[pageLayoutSlice.name]?.sidebarMenu?.menuItems ?? []
);

export const selectStickyNavigation = (state: RootState): Maybe<StickyNavigation> => (
  state[pageLayoutSlice.name].stickyNavigation
);

export const selectSignInBannerSrc = (state: RootState): Url => (
  state[pageLayoutSlice.name].signInBanner ?? ''
);

export const selectSignUpBannerSrc = (state: RootState): Url => (
  state[pageLayoutSlice.name].signUpBanner ?? ''
);

export const useLayoutSelector: TypedUseSelectorHook<{
  [pageLayoutSlice.name]: LayoutState;
}> = useSelector;