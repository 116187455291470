import {
  FC,
  useMemo
} from 'react';
import {
  useNavigate
} from 'react-router-dom';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {
  SystemStyleObject
} from '@mui/system';
import {
  Box,
  Button,
  Stack,
  Typography,
  Divider
} from '@mui/joy';
import {
  isImageUrl,
  useCountdown
} from '@shared/lib';
import {
  type Tournament,
  TournamentStatusEnum
} from '../model';

dayjs.extend(utc);

export type TournamentsListItemProps = Tournament & {
  sx?: SystemStyleObject;
};

export const TournamentsListItem: FC<TournamentsListItemProps> = ({
  name,
  description,
  imageSrc,
  imageUrl,
  endDate,
  startDate,
  status,
  prizeGC,
  prizeSC,
  buttonUrl,
  buttonText,
  sx,
}) => {
  const navigate = useNavigate();

  const timeArray = useCountdown(
    status === TournamentStatusEnum.Active
      ? dayjs.utc(endDate).add(Number(import.meta.env.VITE_ENVIRONMENT_ZONE), 'hours')
      : dayjs.utc(startDate).add(Number(import.meta.env.VITE_ENVIRONMENT_ZONE), 'hours')
  );

  const prizeGCString: string = useMemo(
    () => prizeGC ? `${prizeGC} GC ${prizeSC ? ' + ' : ''}` : '', [prizeGC, prizeSC]
  );

  const prizeSCString: string = useMemo(
    () => prizeSC ? `${prizeSC} SC` : '', [prizeSC]
  );

  const tournamentImageSrc = useMemo(
    () => isImageUrl(imageSrc) ? imageSrc : '/assets/webp/default_tournament_thumbnail.webp', [imageSrc]
  );

  const handleButtonClick = (): void => {
    navigate(buttonUrl);
  };

  const handleImageClick = (): void => {
    if (imageUrl) navigate(imageUrl);
  };

  return (
    <Stack
      sx={({ palette }) => ({
        maxWidth: 410,
        width: '100%',
        gap: 2,
        paddingBlock: 3,
        paddingInline: 2,
        background: palette.common[475],
        borderRadius: 8,
        position: 'relative',
        boxSizing: 'border-box',
        ...sx,
      })}>
      <Box
        sx={({ palette }) => ({
          background: status === TournamentStatusEnum.Active
            ? palette.common[825]
            : palette.common[1060],
          position: 'absolute',
          top: 0,
          left: 0,
          paddingBlock: 0.5,
          paddingInline: 2.5,
          borderEndEndRadius: 8,
          borderTopLeftRadius: 8,
        })}>
        <Typography
          sx={({ palette }) => ({
            fontSize: 12,
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '18px',
            color: palette.common.white,
          })}>
          {status === TournamentStatusEnum.Active
            ? 'Live'
            : 'Upcoming'}
        </Typography>
      </Box>
      <Stack gap={1}>
        <Typography
          sx={({ palette }) => ({
            fontSize: 18,
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '28px',
            color: palette.common.white,
          })}>
          {name}
        </Typography>
        <Typography
          sx={({ palette, breakpoints }) => ({
            fontSize: 16,
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '24px',
            color: palette.common[150],
            [breakpoints.down(912)]: {
              fontSize: 14,
              lineHeight: '20px',
            }
          })}>
          {description}
        </Typography>
      </Stack>
      <Stack gap={1}>
        <Box
          component='img'
          loading='lazy'
          src={tournamentImageSrc}
          onClick={handleImageClick}
          onError={() => console.log('ERROR')}
          sx={{
            width: '100%',
            aspectRatio: 1.5,
            borderRadius: 8,
            ...(imageUrl && ({
              '&:hover': {
                cursor: 'pointer'
              }
            }))
          }}
        />
        <Stack
          direction='row'
          justifyContent='center'
          alignItems='center'
          sx={({ palette }) => ({
            paddingInline: 2,
            paddingBlock: 1,
            gap: 1,
            border: '1px solid',
            borderColor: palette.common[925],
            borderRadius: 8,
            background: palette.common[900],
          })}>
          <Box
            component='img'
            loading='lazy'
            src='/assets/webp/gift_blue_box_red_stripe.webp'
            sx={{
              width: 22,
              height: 28,
            }}
          />
          {(prizeGCString || prizeSCString) && (
            <Typography
              sx={({ palette }) => ({
                fontSize: 14,
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: '20px',
                color: palette.common.white,
                'span': {
                  color: palette.common[1075],
                }
              })}>
              {prizeGCString}<span>{prizeSCString}</span>
            </Typography>
          )}
        </Stack>
        <Stack
          gap={0.5}
          alignItems='center'>
          <Typography
            sx={({ palette }) => ({
              fontSize: 12,
              fontStyle: 'normal',
              fontWeight: 500,
              lineHeight: '18px',
              color: palette.common.white,
            })}>
            {status === TournamentStatusEnum.Active
              ? 'Ends in'
              : 'Starts in'}
          </Typography>
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: '1fr auto 1fr auto 1fr auto 1fr',
              gap: 1.5,
            }}>
            <Stack alignItems='center'>
              <Typography
                sx={({ palette }) => ({
                  fontSize: 20,
                  fontStyle: 'normal',
                  fontWeight: 600,
                  lineHeight: '30px',
                  color: palette.common.white,
                })}>
                {timeArray ? timeArray[0] : 0}
              </Typography>
              <Typography
                sx={({ palette }) => ({
                  fontSize: 12,
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: '18px',
                  color: palette.common[150],
                })}>
                Day
              </Typography>
            </Stack>
            <Divider
              orientation='vertical'
              sx={{
                height: 24,
                margin: 'auto',
              }}
            />
            <Stack alignItems='center'>
              <Typography
                sx={({ palette }) => ({
                  fontSize: 20,
                  fontStyle: 'normal',
                  fontWeight: 600,
                  lineHeight: '30px',
                  color: palette.common.white,
                })}>
                {timeArray ? timeArray[1] : 0}
              </Typography>
              <Typography
                sx={({ palette }) => ({
                  fontSize: 12,
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: '18px',
                  color: palette.common[150],
                })}>
                Hour
              </Typography>
            </Stack>
            <Divider
              orientation='vertical'
              sx={{
                height: 24,
                margin: 'auto',
              }}
            />
            <Stack alignItems='center'>
              <Typography
                sx={({ palette }) => ({
                  fontSize: 20,
                  fontStyle: 'normal',
                  fontWeight: 600,
                  lineHeight: '30px',
                  color: palette.common.white,
                })}>
                {timeArray ? timeArray[2] : 0}
              </Typography>
              <Typography
                sx={({ palette }) => ({
                  fontSize: 12,
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: '18px',
                  color: palette.common[150],
                })}>
                Min
              </Typography>
            </Stack>
            <Divider
              orientation='vertical'
              sx={{
                height: 24,
                margin: 'auto',
              }}
            />
            <Stack alignItems='center'>
              <Typography
                sx={({ palette }) => ({
                  fontSize: 20,
                  fontStyle: 'normal',
                  fontWeight: 600,
                  lineHeight: '30px',
                  color: palette.common.white,
                })}>
                {timeArray ? timeArray[3] : 0}
              </Typography>
              <Typography
                sx={({ palette }) => ({
                  fontSize: 12,
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: '18px',
                  color: palette.common[150],
                })}>
                Sec
              </Typography>
            </Stack>
          </Box>
        </Stack>
        <Button onClick={handleButtonClick}>
          {buttonText}
        </Button>
      </Stack>
    </Stack>
  );
};
