import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { Stack, Avatar } from '@mui/joy';
import { useTheme, useMediaQuery } from '@mui/material';
import { Button } from '@shared/ui';
import { CurrencyCodesEnum } from '@shared/types';
import {
  toggleCurrencyCode,
  useSessionSelector,
  selectToggledCurrencyCode,
} from '@entities/session';

export const ToggleCoinsWithoutBalance: FC = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(912));

  const toggledCurrencyCode = useSessionSelector(selectToggledCurrencyCode);

  const handleToggle = (newValue: CurrencyCodesEnum) => (): void => {
    dispatch(toggleCurrencyCode(newValue));
  };

  return (
    <Stack
      direction='row'
      sx={({ palette }) => ({
        borderWidth: 1,
        borderRadius: 8,
        borderStyle: 'solid',
        borderColor: palette.common[925],
        backgroundColor: palette.common[475],
      })}
    >
      <Button
        {...{
          variant: 'outlined',
          color: 'primary',
          ...(toggledCurrencyCode === CurrencyCodesEnum.SCO && {
            variant: 'plain',
            color: 'neutral',
          }),
        }}
        sx={{ padding: '5px 11px' }}
        size={isMobile ? 'md' : 'lg'}
        onClick={handleToggle(CurrencyCodesEnum.GCO)}>
        <Avatar
          src='/assets/webp/large-gold-coin.webp'
          sx={{
            width: 36,
            height: 'auto'
          }}
        />
      </Button>
      <Button
        {...{
          variant: 'outlined',
          color: 'success',
          ...(toggledCurrencyCode === CurrencyCodesEnum.GCO && {
            variant: 'plain',
            color: 'neutral',
          }),
        }}
        sx={{ padding: '5px 11px' }}
        size={isMobile ? 'md' : 'lg'}
        onClick={handleToggle(CurrencyCodesEnum.SCO)}>
        <Avatar
          src='/assets/webp/large-silver-coin.webp'
          sx={{
            width: 36,
            height: 'auto'
          }}
        />
      </Button>
    </Stack>
  );
};
