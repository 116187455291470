import { FC } from 'react';
import { Button, Stack } from '@mui/joy';
import { selectHasIdVerifiedTag, useSessionSelector } from '@entities/session';
import { ArrowLeftIcon } from '../assets';
import { useKycVerification } from '../model';
import { KycStatusVerificationMessage } from './kyc-status-verification-message.component';

export const KycVerificationCard: FC = () => {
  const { beginKycVerification } = useKycVerification();
  const hasIdVerifiedTag = useSessionSelector(selectHasIdVerifiedTag);
  return (
    <Stack
      justifyContent='space-between'
      height={120}>
      <Stack
        direction='row'
        alignItems='center'
        justifyContent='space-between'
        columnGap={1}>
        <ArrowLeftIcon
          sx={{
            height: '100%',
            width: 'fit-content'
          }}
        />
        <KycStatusVerificationMessage />
      </Stack>
      <Button
        color='primary'
        disabled={hasIdVerifiedTag}
        onClick={beginKycVerification}>
        Start Now
      </Button>
    </Stack>
  );
};