import { FC, useEffect } from 'react';
import { Sheet, Stack } from '@mui/joy';
import { useDispatch } from 'react-redux';
import {
  UserIdentityCard,
  UserVerificationIndicator,
  WithPullingPlayerInfo
} from '@entities/session';
import {
  UploadDocumentsForm
} from '@features/upload-documents';
import {
  EditFullProfileForm
} from '@features/edit-full-profile';
import {
  useSendDataToGtm,
  useGoogleAds
} from '@features/collect-analytics';
import {
  KycVerificationCard
} from '@features/begin-kyc-verification';
import {
  TransactionList
} from '@widgets/transaction-list';
import {
  AccountTabEnum,
  resetAccountPage,
  selectCurrentTab,
  useAccountPageSelector
} from '../model';
import {
  AccountTabs
} from './account-tabs.component';
import {
  SettingTab
} from './settings-tab.component';

export const Account: FC = () => {
  useGoogleAds({ adsLabel: 'PRZWCPysh-QZEIrhv9g9' });
  useSendDataToGtm({
    dataLayer: {
      event: 'page_view',
      pagePath: window.location.pathname,
      pageTitle: 'account'
    },
  });

  const dispatch = useDispatch();
  const currentTab = useAccountPageSelector(selectCurrentTab);

  useEffect(() => {
    return () => {
      dispatch(resetAccountPage());
    }
    //eslint-disable-next-line
  }, []);
  return (
    <WithPullingPlayerInfo>
      <Sheet
        sx={({ breakpoints }) => ({
          width: '100%',
          maxWidth: 1280,
          marginBlock: 'auto',
          paddingBlockStart: 2,
          paddingBlockEnd: 8,
          display: 'grid',
          gridTemplateColumns: '341px auto',
          columnGap: 3,
          [breakpoints.down(1350)]: { paddingInline: 2 },
          [breakpoints.down(414)]: { paddingBlockEnd: 3 },
          [breakpoints.down(768)]: { gridTemplateColumns: '100%' }
        })}>
        <Sheet
          sx={({ breakpoints }) => ({
            marginBlockEnd: .5,
            gridColumnStart: 1,
            gridColumnEnd: 3,
            [breakpoints.down(768)]: {
              gridColumnStart: 1,
              gridColumnEnd: 2,
              gridRowStart: 2,
              gridRowEnd: 3,
            }
          })}>
          <UserVerificationIndicator />
        </Sheet>
        <Sheet
          sx={({ breakpoints }) => ({
            gridColumnStart: 1,
            gridColumnEnd: 2,
            marginBlockEnd: 1.25,
            [breakpoints.down(768)]: {
              borderWidth: 1,
              marginBlockEnd: 3,
              gridColumnStart: 1,
              gridColumnEnd: 2,
              gridRowStart: 3,
              gridRowEnd: 4,
            }
          })}>
          <UserIdentityCard />
        </Sheet>
        <Sheet
          sx={({ breakpoints }) => ({
            position: 'relative',
            gridColumnStart: 1,
            gridColumnEnd: 2,
            [breakpoints.down(768)]: {
              marginBlockEnd: 3,
              gridColumnStart: 1,
              gridColumnEnd: 2,
              gridRowStart: 1,
            },
            [breakpoints.down(390)]: {
              height: 40,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }
          })}>
          <AccountTabs />
        </Sheet>
        <Stack
          direction='row'
          gap={1.25}
          sx={({ breakpoints, palette }) => ({
            flexWrap: 'wrap',
            gridColumnStart: 2,
            gridColumnEnd: 3,
            gridRowStart: 2,
            gridRowEnd: 5,
            height: 'auto',
            borderRadius: 8,
            borderWidth: 2,
            borderStyle: 'solid',
            borderColor: palette.common[925],
            padding: 3,
            [breakpoints.down(768)]: {
              gridColumnStart: 1,
              gridColumnEnd: 2,
              gridRowStart: 4,
              gridRowEnd: 5,
              borderRadius: 0,
              borderWidth: 0,
              padding: 0,
            }
          })}>
          {currentTab === AccountTabEnum.Profile && (
            <EditFullProfileForm />
          )}
          {currentTab === AccountTabEnum.Setting && <SettingTab />}
          {currentTab === AccountTabEnum.Docs && (
            <UploadDocumentsForm
              kycSlot={(
                <KycVerificationCard />
              )}
            />
          )}
          {currentTab === AccountTabEnum.Transactions && <TransactionList />}
        </Stack>

      </Sheet>
    </WithPullingPlayerInfo>
  );
};