import { FC } from 'react';
import { SvgIcon, SvgIconProps, useTheme } from '@mui/joy';

export const ArrowLeftIcon: FC<SvgIconProps> = props => {
  const { palette } = useTheme();
  return (
    <SvgIcon
      width='88'
      height='64'
      viewBox='0 0 88 64'
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      {...props}>
      <path d='M11.0232 0.5H76.9768C82.8117 0.5 87.5 5.23978 87.5 11.0571V52.8492C87.5 58.7602 82.7191 63.5 76.9768 63.5H11.0232C5.19374 63.5 0.5 58.672 0.5 52.8492V11.1508C0.5 5.23977 5.28094 0.5 11.0232 0.5ZM36.2558 22.2079C36.2558 17.3457 32.3321 13.3682 27.5116 13.3682C22.6911 13.3682 18.7674 17.3457 18.7674 22.2079C18.7674 27.0701 22.6911 31.0476 27.5116 31.0476C32.3321 31.0476 36.2558 27.0701 36.2558 22.2079ZM10.4489 46.529C10.1267 47.5297 10.3495 48.5377 10.9982 49.3139C11.5718 50.1117 12.5627 50.5381 13.5242 50.5381H41.4989C43.3552 50.5381 44.7779 49.0906 44.7779 47.2269C44.7779 46.7006 44.6752 46.2621 44.4701 45.8322C42.3595 38.1465 35.5066 32.8587 27.6042 32.8587C19.4076 32.8587 12.3608 38.5422 10.4489 46.529ZM49.4653 39.3873H60.4884C62.3447 39.3873 63.7674 37.9398 63.7674 36.0761C63.7674 34.2016 62.2412 32.765 60.4884 32.765H49.4653C47.5983 32.765 46.1863 34.3171 46.1863 36.0761C46.1863 37.9506 47.7124 39.3873 49.4653 39.3873ZM49.4653 28.2365H74.2905C76.0651 28.2365 77.4768 26.7778 77.4768 24.9253C77.4768 23.0508 75.9507 21.6142 74.1979 21.6142H49.4653C47.5983 21.6142 46.1863 23.1663 46.1863 24.9253C46.1863 26.7998 47.7124 28.2365 49.4653 28.2365Z'
        fill={palette.common[475]}
        stroke={palette.common[300]}
      />
    </SvgIcon>
  );
}
