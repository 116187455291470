import { FC, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import {
  Modal,
  ModalDialog,
  Stack,
  Typography,
  styled
} from '@mui/joy';
import _PaymentIQCashier, {
  type IPiqCashierApiMethods,
  type Environment
} from 'paymentiq-cashier-bootstrapper';
import {
  useTheme,
  useMediaQuery
} from '@mui/material';
import { CloseIcon, IconButton } from '@shared/ui';
import {
  selectShortProfile,
  selectSessionKey,
  useSessionSelector
} from '@entities/session';
import {
  mapRedeemCashierConfig
} from '../lib';
import {
  closeConfirmCoinRedemptionPopup,
  selectCoinRedeem,
  selectIsOpenConfirmCoinRedemptionPopup,
  useRedeemCoinsSelector
} from '../model';

export const ConfirmCoinRedemptionIframe = styled('div')(() => ({
  width: '100%',
  height: '100%',
  border: 'none',
  '@media(orientation: landscape) and (max-height: 500px)': {
    width: '100%',
    height: 'calc(100vh - 4.25rem)'
  }
}));

export type PaymentIQCashierConfirmCoinRedemptionPopupProps = {
  onRedeemStart: (amount: string) => void,
  onRedeemSuccess: () => void,
  onRedeemFail: () => void
}

export const PaymentIQCashierConfirmCoinRedemptionPopup: FC<PaymentIQCashierConfirmCoinRedemptionPopupProps> = ({
  onRedeemStart,
  onRedeemSuccess,
  onRedeemFail
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(490));

  const dispatch = useDispatch();
  const cashierRef = useRef<HTMLDivElement>(null);

  const shortProfile = useSessionSelector(selectShortProfile);
  const sessionKey = useSessionSelector(selectSessionKey);
  const open = useRedeemCoinsSelector(selectIsOpenConfirmCoinRedemptionPopup);
  const coinRedeem = useRedeemCoinsSelector(selectCoinRedeem);


  useEffect(() => {
    if (cashierRef.current) {
      const config = mapRedeemCashierConfig({
        environment: `${import.meta.env.VITE_PAYMENT_ENVIRONMENT}` as Environment,
        merchantId: `${import.meta.env.VITE_MERCHANT_ID}`,
        userId: `${shortProfile?.partyId}`,
        sessionId: `${sessionKey}`,
        containerHeight: `${cashierRef.current?.clientHeight}px`,
        containerWidth: `${cashierRef.current?.clientWidth}px`,
        amount: `${coinRedeem}`,
      });

      new _PaymentIQCashier('#cashier',
        config,
        (api: IPiqCashierApiMethods) => {
          api.on({
            cashierInitLoad: () => onRedeemStart(`${coinRedeem}`),
            success: () => onRedeemSuccess(),
            failure: () => onRedeemFail(),
            validationFailed: () => onRedeemFail(),
          });
        });
    }
    // eslint-disable-next-line
  }, [cashierRef]);

  const handleClose = (): void => {
    dispatch(closeConfirmCoinRedemptionPopup());
  };

  return (
    <Modal open={open ?? false} disablePortal>
      <ModalDialog
        layout={(
          isMobile
            ? 'fullscreen'
            : 'center'
        )}
        sx={({ breakpoints, palette }) => ({
          width: '100%',
          height: '100%',
          maxWidth: 488,
          maxHeight: 678,
          backgroundColor: palette.common[925],
          [breakpoints.down(491)]: {
            maxHeight: '100%',
            borderRadius: 0,
            boxShadow: 'none',
          },
        })}>
        <Stack
          flexDirection='column'
          sx={({ breakpoints }) => ({
            width: '100%',
            height: '100%',
            padding: 3,
            [breakpoints.down(490)]: {
              paddingInline: 2,
              paddingTop: 2,
              paddingBottom: 4,
            },
            [breakpoints.down(390)]: {
              paddingInline: 1,
              paddingBottom: 2,
            },
          })}>
          <Stack
            direction='row'
            alignItems='center'
            justifyContent='space-between'
            sx={{
              width: '100%',
              marginBottom: 3,
            }}>
            <Typography
              level='h3'
              fontSize='1.25rem'>
              Checkout
            </Typography>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Stack>
          <Stack
            ref={cashierRef}
            direction='column'
            width='100%'
            height='100%'>
            <ConfirmCoinRedemptionIframe id='cashier' />
          </Stack>
        </Stack>
      </ModalDialog>
    </Modal>
  );
};
