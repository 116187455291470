import {
  ComponentType,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import {
  isDefined,
  useModalQueryParam,
  useQueryParamModalVisibility
} from '@shared/lib';
import {
  useToaster
} from '@shared/ui';
import {
  selectIsAuthenticated,
  selectShortProfile,
  selectHasIdVerifiedTag,
  useSessionSelector,
} from '@entities/session';
import {
  type GenerateKycTokenDataResponse,
  useGenerateKycTokenMutation
} from '../api';
import {
  KycVerificationContext,
} from '../model';
import {
  usePollingKycStatusUpdate
} from '../lib';
import {
  BeginKycVerificationModal
} from './begin-kyc-verification-modal.component';
import {
  YourProfileVerifiedModal
} from './your-profile-verified-modal.component';

export const withKycVerification = <T extends object>
  (WrappedComponent: ComponentType<T>) =>
  (props: T) => {
    const { error } = useToaster();

    const isAuthenticated = useSessionSelector(selectIsAuthenticated);
    const hasIdVerifiedTag = useSessionSelector(selectHasIdVerifiedTag);
    const profile = useSessionSelector(selectShortProfile);
    const partyId = useMemo(() => profile?.partyId ?? '', [profile]);

    const openKycVerificationModal = useQueryParamModalVisibility('kyc-verify');

    const [openModal, setOpenModal] = useState<boolean>(false);
    const [openYourProfileVerifiedModal, setOpenYourProfileVerifiedModal] = useState<boolean>(false);
    const [accessToken, setAccessToken] = useState<Maybe<string>>(null);

    const { closeModal } = useModalQueryParam();
    const [generateKycToken] = useGenerateKycTokenMutation();
    const { pollKycStatus } = usePollingKycStatusUpdate();

    const handleBeginKycVerification = useCallback(async (): Promise<void> => {
      const isAllowed = isAuthenticated && isDefined(partyId);
      if (!isAllowed) return;

      try {
        const result = await generateKycToken({ partyId }) as { data: GenerateKycTokenDataResponse };
        if (result?.data?.token) {
          setAccessToken(result.data.token);
          setOpenModal(true);
        } else {
          error({
            message: result?.data?.error ?? 'Failed to generate KYC token',
            autoHideDuration: 2000
          });
          setOpenModal(false);
          setAccessToken(null);
          closeModal('kyc-verify');
        }
      } catch (err) {
        console.error('Error during KYC token generation:', err);
        error({
          message: 'An unexpected error occurred during KYC token generation',
          autoHideDuration: 2000
        });
        setOpenModal(false);
        setAccessToken(null);
        closeModal('kyc-verify');
      }
    }, [closeModal, error, generateKycToken, isAuthenticated, partyId]);


    const handleTerminateKycVerification = useCallback((): void => {
      setOpenModal(false);
      closeModal('kyc-verify');
      // eslint-disable-next-line
    }, []);

    const handleClosedYourProfileVerifiedModal = useCallback((): void => {
      setOpenModal(false);
      setOpenYourProfileVerifiedModal(false);
      closeModal('kyc-verify');
      // eslint-disable-next-line
    }, []);

    const handleCompletedKycVerification = useCallback(async (): Promise<void> => {
      try {
        await pollKycStatus();
      } catch (e) {
        error({
          message: 'Verification service is currently unavailable. Please contact customer support for more information.',
          autoHideDuration: 2000
        });
      }
      // eslint-disable-next-line
    }, []);

    const handleErrorKycVerification = useCallback(async (): Promise<void> => {
      setOpenModal(false);
      closeModal('kyc-verify');
      error({
        message: 'Verification service is currently unavailable. Please contact customer support for more information.',
        autoHideDuration: 2000
      });
      // eslint-disable-next-line
    }, []);

    const expirationHandler = async (): Promise<string> => {
      try {
        const result = await generateKycToken({ partyId }) as { data: GenerateKycTokenDataResponse };;
        if (result?.data?.token) {
          setAccessToken(result.data.token);
          return result.data.token;
        } else {
          error({
            message: result?.data?.error ?? 'Failed to refresh KYC token',
            autoHideDuration: 2000
          });
          closeModal('kyc-verify');
          return '';
        }
      } catch (e) {
        error({
          message: 'An error occurred while refreshing the KYC token',
          autoHideDuration: 2000
        });
        closeModal('kyc-verify');
        return '';
      }
    };

    useEffect(() => {
      if (hasIdVerifiedTag && openKycVerificationModal)
        setOpenYourProfileVerifiedModal(true);
      else if (openKycVerificationModal)
        handleBeginKycVerification();
      return () => {
        setAccessToken(null);
        setOpenModal(false);
      };
      // eslint-disable-next-line
    }, [openKycVerificationModal]);

    return (
      <KycVerificationContext.Provider
        value={{
          beginKycVerification: handleBeginKycVerification,
          terminateKycVerification: handleTerminateKycVerification
        }}>
        <WrappedComponent {...props} />
        <BeginKycVerificationModal
          open={openModal}
          partyId={profile?.partyId ?? ''}
          accessToken={accessToken ?? ''}
          expirationHandler={expirationHandler}
          onClose={handleTerminateKycVerification}
          onCompleted={handleCompletedKycVerification}
          onError={handleErrorKycVerification}
        />
        <YourProfileVerifiedModal
          open={openYourProfileVerifiedModal}
          onClose={handleClosedYourProfileVerifiedModal}
        />
      </KycVerificationContext.Provider>
    );
  };