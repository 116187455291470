import { FC, useState } from 'react';
import { Box, Skeleton } from '@mui/joy';
import { selectSignUpBannerSrc, useLayoutSelector } from '../model';

export const SingUpPromoBanner: FC = () => {
  const [imageLoaded, setImageLoaded] = useState<boolean>(false);
  const signUpBannerSrc = useLayoutSelector(selectSignUpBannerSrc);

  const handleImageLoad = (): void => {
    setImageLoaded(true);
  };

  return (
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        right: 0,
        left: 0,
        bottom: 0,
      }}>
      <Box
        component='img'
        src={signUpBannerSrc || '/assets/webp/welcome_popup_desktop.webp'}
        onLoad={handleImageLoad}
        alt='Sign up Banner'
        sx={{
          height: '100%',
          width: '100%',
          objectFit: 'cover',
          display: imageLoaded ? 'block' : 'none',
        }}
      />
      {!imageLoaded && (
        <Skeleton animation='wave' />
      )}
    </Box>
  );
};
