import { ComponentType, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ThunkDispatch, UnknownAction } from '@reduxjs/toolkit';
import { useConcreteSocket } from '@shared/api';
import {
  type ChannelMessage,
  mapMessage,
  setMessage,
  fetchMessages,
  clearAllMessages
} from '@entities/messages';
import {
  selectSessionKey,
  selectShortProfile,
  useSessionSelector
} from '@entities/session';
import { withSocket } from './with-socket';

export const withNotifications = <T extends object>(WrappedComponent: ComponentType<T>) =>
  withSocket((props: T) => {
    const dispatch = useDispatch<ThunkDispatch<unknown, unknown, UnknownAction>>();
    const { subscribe, connectSocket, disconnectSocket, isConnected } = useConcreteSocket(
      import.meta.env.VITE_OMEGA_WS_PLAYER_INBOX_CHANNEL ?? ''
    );

    const sessionKey = useSessionSelector(selectSessionKey);
    const shortProfile = useSessionSelector(selectShortProfile);

    useEffect(() => {
      if (sessionKey && shortProfile?.partyId && !isConnected) {
        const url: URL = new URL(import.meta.env.VITE_OMEGA_WS ?? '');
        url.searchParams.append('channel', import.meta.env.VITE_OMEGA_WS_PLAYER_INBOX_CHANNEL ?? '');
        url.searchParams.append('id', shortProfile.partyId);
        url.searchParams.append('sessionKey', sessionKey);
        url.searchParams.append('language', 'en');

        connectSocket(url.toString());
      }
    }, [sessionKey, shortProfile, isConnected, connectSocket]);

    useEffect(() => {
      if (isConnected) {
        const subscription = subscribe((data: any) => {
          const mappedMessage: ChannelMessage = mapMessage(data.data);

          dispatch(
            setMessage({
              channelId: import.meta.env.VITE_OMEGA_WS_PLAYER_INBOX_CHANNEL ?? '',
              message: mappedMessage
            })
          );
        });
        return () => {
          subscription();
        };
      }
    }, [isConnected, subscribe, dispatch]);

    useEffect(() => {
      if (isConnected && !shortProfile) {
        disconnectSocket();
      }
    }, [isConnected, shortProfile, disconnectSocket]);

    useEffect(() => {
      if (shortProfile && sessionKey) {
        dispatch(
          fetchMessages({
            channel: import.meta.env.VITE_OMEGA_WS_PLAYER_INBOX_CHANNEL ?? '',
            partyId: shortProfile!.partyId,
            sessionKey,
            size: 100,
          })
        );
      } else {
        dispatch(clearAllMessages({
          channelId: import.meta.env.VITE_OMEGA_WS_PLAYER_INBOX_CHANNEL ?? ''
        }));
      }
    }, [shortProfile, sessionKey, dispatch]);

    return (
      <WrappedComponent {...props} />
    );
  });
