import { baseOmegaApi } from '@shared/api';
import { type GameWinnersState } from '../model';
import { mapGameWinners } from '../lib';

export const gameWinnersApiSlice = baseOmegaApi.injectEndpoints({
  endpoints: (build) => ({
    getGameWinnerList: build.query<Pick<GameWinnersState, 'winners'>, void>({
      query: () => ({
        url: '/ips/getGameWinnerList', params: {
          period: 7,
          size: 50,
          domain: import.meta.env.VITE_DOMAIN,
          uniqueWinner: true,
        }
      }),
      extraOptions: { showLoader: false },
      transformResponse: (response: any) => mapGameWinners(response),
    }),
  }),
});

export const { useGetGameWinnerListQuery } = gameWinnersApiSlice;
