import { baseApi } from '@shared/api';

export type GenerateKycToken = {
  partyId: string;
};

export type GenerateKycTokenDataResponse = {
  token?: Maybe<string>;
  error?: Maybe<string>;
};

export const beginKycVerificationApiSlicer = baseApi.injectEndpoints({
  endpoints: build => ({
    generateKycToken: build.mutation<GenerateKycTokenDataResponse, GenerateKycToken>({
      query: ({ partyId }) => ({
        url: `/api/kyc-verification/generate-kyc-token?partyId=${partyId}`,
        method: 'POST',
      }),
      extraOptions: { auth: true, showLoader: true }
    }),
  }),
});

export const { useGenerateKycTokenMutation } = beginKycVerificationApiSlicer;