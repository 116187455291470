import {
  TypedUseSelectorHook,
  useSelector
} from 'react-redux';
import {
  createSelector
} from '@reduxjs/toolkit';
import {
  UserRegistrationStatusEnum
} from '@shared/types';
import type {
  SessionState,
} from '@entities/session';
import {
  sessionSlice,
  selectKycStatus,
  selectIsAuthenticated,
  selectRegistrationStatus,
  selectHasFirstTimeDepositTag,
  selectHasIdVerifiedTag,
  UserKycStatusEnum,
} from '@entities/session';

type RootState = {
  [sessionSlice.name]: SessionState;
};

type BuyCoinsRequirements = {
  isAuthenticated: boolean
  kycRequired: boolean;
  buyingAllowed: boolean;
  accessRestricted: boolean;
};

export const selectBuyCoinsRequirements = (hasBuyQueryParam: boolean) => createSelector(
  [selectIsAuthenticated, selectRegistrationStatus, selectKycStatus, selectHasFirstTimeDepositTag, selectHasIdVerifiedTag],
  (isAuthenticated, registrationStatus, kycStatus, hasFirstTimeDepositTag, hasIdVerifiedTag): BuyCoinsRequirements => {
    const isQuickReg = registrationStatus === UserRegistrationStatusEnum.QuickReg;
    const isPlayer = registrationStatus === UserRegistrationStatusEnum.Player;

    const isKycPass = kycStatus === UserKycStatusEnum.Pass;
    const isKycPending = kycStatus === UserKycStatusEnum.Pending;
    const isKycRequested = kycStatus === UserKycStatusEnum.Requested;
    const isKycFailed = kycStatus === UserKycStatusEnum.Failed;
    const isKycUnknown = kycStatus === UserKycStatusEnum.Unknown;

    const defaultBuyCoinsRequirements: BuyCoinsRequirements = {
      isAuthenticated,
      buyingAllowed: false,
      kycRequired: false,
      accessRestricted: false
    };

    if (!hasBuyQueryParam) return defaultBuyCoinsRequirements;

    if (!isAuthenticated) {
      return { ...defaultBuyCoinsRequirements, isAuthenticated: false };
    };

    const isAccessRestricted = isQuickReg ||
      (isPlayer && isKycFailed) ||
      (isPlayer && (isKycPending || isKycRequested) && hasFirstTimeDepositTag && hasIdVerifiedTag);
    if (isAccessRestricted) {
      return { ...defaultBuyCoinsRequirements, accessRestricted: true };
    }

    const isKycRequired =
      (isPlayer && isKycUnknown && hasFirstTimeDepositTag) ||
      (isPlayer && (isKycPending || isKycRequested) && hasFirstTimeDepositTag);
    if (isKycRequired) {
      return { ...defaultBuyCoinsRequirements, kycRequired: true };
    }

    const isBuyingAllowed =
      (isPlayer && isKycPass) ||
      (isPlayer && isKycUnknown) ||
      (isPlayer && (isKycPending || isKycRequested));
    if (isBuyingAllowed) {
      return { ...defaultBuyCoinsRequirements, buyingAllowed: true };
    }

    return defaultBuyCoinsRequirements;
  });

export const selectAdditionalDocumentsRequired = createSelector(
  [selectRegistrationStatus, selectKycStatus, selectHasFirstTimeDepositTag, selectHasIdVerifiedTag],
  (registrationStatus, kycStatus, hasFirstTimeDepositTag, hasIdVerifiedTag): boolean => {
    const isPlayer = registrationStatus === UserRegistrationStatusEnum.Player;
    const isKycPending = kycStatus === UserKycStatusEnum.Pending;
    const isKycRequested = kycStatus === UserKycStatusEnum.Requested;

    return (isPlayer && (isKycPending || isKycRequested) && hasFirstTimeDepositTag && hasIdVerifiedTag);
  });

export const selectIsPlayerVerified = (state: RootState): boolean => {
  const fullProfile = state[sessionSlice.name]?.fullProfile;
  return fullProfile?.kycStatus === UserKycStatusEnum.Pass &&
    fullProfile?.registrationStatus === UserRegistrationStatusEnum.Player;
};

export const useBuyCoinsSelector: TypedUseSelectorHook<{
  [sessionSlice.name]: SessionState;
}> = useSelector;
