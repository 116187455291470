import { FC, useMemo } from 'react';
import { Typography, useTheme } from '@mui/joy';
import { selectKycStatus, useSessionSelector, UserKycStatusEnum } from '@entities/session';

export const KycStatusVerificationMessage: FC = () => {
  const { palette } = useTheme();
  const kycStatus = useSessionSelector(selectKycStatus);

  const kycStatusMessage = useMemo(() => {
    const _kycStatus = kycStatus ?? UserKycStatusEnum.Unknown;
    const messages: { [key in UserKycStatusEnum]?: { label: string; color: Color; } } = {
      [UserKycStatusEnum.Pending]: { label: 'ID verification in progress', color: palette.common[1060] },
      [UserKycStatusEnum.Requested]: { label: 'ID verification in progress', color: palette.common[1060] },
      [UserKycStatusEnum.Pass]: { label: 'Your identity is now verified', color: palette.common[825] },
      [UserKycStatusEnum.Failed]: { label: 'Your identity verification was not successful', color: palette.common.error },
    };
    return messages[_kycStatus] ?? { label: 'Your identity is not verified yet', color: palette.common[150] };
  }, [kycStatus, palette]);

  return (
    <Typography
      sx={{
        color: kycStatusMessage.color,
        fontSize: 14,
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '20px'
      }}>
      {kycStatusMessage.label}
    </Typography>
  );
};