import { createContext, useContext } from 'react';

export type KycVerificationContextType = {
  beginKycVerification(): Promise<void>;
  terminateKycVerification(): void;
};

export const KycVerificationContext = createContext<Maybe<KycVerificationContextType>>(null);

export const useKycVerification = (): KycVerificationContextType => {
  const context = useContext(KycVerificationContext);
  if (!context)
    throw new Error('useKycVerification must be used within a KycVerificationProvider');
  return context;
};