import { FC, Fragment, useEffect, useId, useState } from 'react';
import type { DropzoneOptions } from 'react-dropzone';
import { useDropzone, FileRejection } from 'react-dropzone';
import { Box, Stack, Typography } from '@mui/joy';
import { isEmpty } from '@shared/lib';
import { getFileName, getFileSize } from '../lib';

export type SingleDocumentDropzoneProps = Omit<Omit<DropzoneOptions, 'onError'>, 'onDrop'> & {
  onDrop?(file: Nullable<File>, hasError: boolean): void;
  shouldClear?: boolean;
};

// TODO: refactor me
export const SingleDocumentDropzone: FC<SingleDocumentDropzoneProps> = ({
  onDrop,
  maxSize,
  shouldClear,
  ...props
}) => {
  const id = useId();

  const [file, setFile] = useState<Maybe<File>>(null);
  const [errors, setErrors] = useState<Array<string>>([]);

  useEffect(() => {
    if (shouldClear) {
      setFile(null);
      setErrors([]);
    }
  }, [shouldClear]);

  const { getRootProps, getInputProps } = useDropzone({
    ...props,
    maxSize,
    multiple: false,
    onDrop(accepted: Array<File>, rejected: Array<FileRejection>) {
      if (accepted.length) {
        const file = accepted[0];
        setFile(file);
        onDrop?.(file, false);
        setErrors([]);
      }

      if (rejected.length) {
        const errors = rejected[0].errors
          ?.map(({ message }) => message) ?? ['Uploading error'];
        setErrors(errors);
        setFile(null);
        onDrop?.(null, true);
      }

    },
    onError(error: Error) {
      setErrors([`Uploading error: ${error.message}`]);
      onDrop?.(null, true);
    }
  });

  return (
    <Stack
      key={id}
      direction='row'
      alignItems='center'
      justifyContent='center'
      sx={({ palette, breakpoints }) => ({
        minHeight: 176,
        borderRadius: 8,
        borderStyle: 'dashed',
        borderWidth: 2,
        borderColor: isEmpty(errors)
          ? palette.common[700]
          : palette.common.error,
        backgroundColor: palette.common[475],
        gap: 1.5,
        [breakpoints.down(768)]: {
          minHeight: 80,
        },
      })}
      {...getRootProps({ className: 'dropzone' })}>
      <input {...getInputProps()} />
      {file && isEmpty(errors) && (
        <Box
          component='img'
          loading='lazy'
          src='/assets/webp/green-picture.webp'
          alt='Image'
          sx={{
            height: 24,
            width: 24,
          }}
        />
      )}
      <Stack
        direction='column'
        alignItems='center'>
        {isEmpty(errors) ? (
          <Fragment>
            <Typography
              sx={({ palette }) => ({
                fontSize: 16,
                fontStyle: 'normal',
                fontWeight: file ? 400 : 500,
                lineHeight: '24px',
                color: file
                  ? palette.common.white
                  : palette.common[700],
              })}>
              {getFileName(file, { returnValueIfEmpty: 'Upload a file' })}
            </Typography>
            <Typography
              sx={({ palette }) => ({
                fontSize: 14,
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '20px',
                color: palette.common[150],
              })}>
              {getFileSize(file, { returnValueIfEmpty: 'PNG, JPG, PDF up to 6 MB' })}
            </Typography>
          </Fragment>
        ) : (
          errors.map((error: string, key: number) => (
            <Typography
              key={key}
              sx={({ palette }) => ({
                fontSize: 16,
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '24px',
                color: palette.common.error,
              })}>
              {error}
            </Typography>
          ))
        )}
      </Stack>
    </Stack>
  );
};