import compose from 'compose-function';
import { withJoyTheme, withMuiTheme } from '@shared/theme';
import { withGoogleAdsConversion } from '@features/collect-analytics';
import { withStore } from './with-store';
import { withCheckLoggedIn } from './with-check-logged-in';
import { withLoaderRoot } from './with-loader-root';
import { withToast } from './with-toast';
import { withGtm } from './with-gtm';
import { withFullScreenListener } from './with-full-screen-listener';

export const withProviders = compose(
  withGoogleAdsConversion,
  withJoyTheme,
  withMuiTheme,
  withStore,
  withGtm,
  withCheckLoggedIn,
  withLoaderRoot,
  withToast,
  withFullScreenListener,
);
