import Cookies from 'js-cookie';
import {
  UserStateEnum
} from '@shared/types';

export const clearAuthTokenCookieSetStatusCookieAndCleanup = (): void => {
  const options = { domain: import.meta.env.VITE_COOKIE_DOMAIN };
  Cookies.set('userStatus', UserStateEnum.UserIsLoggedOut, options);
  Cookies.remove('authToken', options);
};
