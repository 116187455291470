import { mapper } from '@shared/lib';
import { GamesItem } from '@entities/games';

type MapGamesItem = (data: any) => GamesItem;
export const mapGamesItem: MapGamesItem = mapper([
  { name: 'id', from: 'id' },
  { name: 'name', from: 'name' },
  { name: 'launchId', from: 'launchId' },
  { name: 'segmentId', from: 'segmentId' },
  { name: 'segmentName', from: 'segmentName' },
  { name: 'productId', from: 'productId' },
  { name: 'productCode', from: 'productCode' },
  { name: 'bannerUrl', from: 'bannerUrl.url' },
  { name: 'supportsMobile', from: 'supportsMobile' },
  { name: 'supportsDesktop', from: 'supportsDesktop' },
  { name: 'omegaId', from: 'omegaId' },
  { name: 'gameId', from: 'gameId' },
  { name: 'rtp', from: 'rtp' },
]);
