import {
  useCallback
} from 'react';
import {
  useDispatch
} from 'react-redux';
import {
  incrementActiveRequests,
  decrementActiveRequests
} from '@shared/ui/circular-loader';
import {
  UserKycStatusEnum,
  useLazyGetPlayerInfoQuery
} from '@entities/session';

const kycStatuses = [
  UserKycStatusEnum.Pass,
  UserKycStatusEnum.Failed,
  UserKycStatusEnum.Suspended,
  UserKycStatusEnum.Requested
];

const intervals = [
  2000,
  3000,
  5000,
  8000,
  13000,
  21000,
  34000
];

export type UsePollingKycStatusUpdate = {
  pollKycStatus(): Promise<void>;
};

export const usePollingKycStatusUpdate = (): UsePollingKycStatusUpdate => {
  const dispatch = useDispatch();
  const [getPlayerInfo] = useLazyGetPlayerInfoQuery();

  const pollKycStatus = useCallback(async (): Promise<void> => {
    dispatch(incrementActiveRequests());

    const pollWithIntervals = async (): Promise<void> => {
      return new Promise<void>(async (resolve, reject) => {
        for (const delay of intervals) {
          try {
            const profile = await getPlayerInfo().unwrap();
            const isKycStatusValid = kycStatuses.includes(profile.kycStatus);
            if (isKycStatusValid) {
              dispatch(decrementActiveRequests());
              resolve();
              return;
            }
          } catch (err) {
            dispatch(decrementActiveRequests());
            reject(new Error('Verification service is currently unavailable.'));
            return;
          }

          await new Promise((res) => setTimeout(res, delay));
        }

        dispatch(decrementActiveRequests());
        reject(new Error('Verification service is currently unavailable.'));
        return;
      });
    };

    await pollWithIntervals();
    // eslint-disable-next-line
  }, []);

  return { pollKycStatus };
};
