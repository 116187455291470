import { useRef } from 'react';
import {
  AuthResponse,
  LoginStatus,
  useFacebookLogin
} from 'react-facebook-login-hook';

export type UseFacebookAuthentication = {
  authenticateWithFacebook(): Promise<boolean>;
};

export type UseFacebookAuthenticationProps = {
  onError?: (error: any) => void;
  onSuccess(response: {
    status: LoginStatus;
    authResponse: AuthResponse;
  }): void;
};

export const useFacebookAuthentication = (
  { onSuccess, onError }: UseFacebookAuthenticationProps
): UseFacebookAuthentication => {
  const authenticateResolveCallbackRef =
    useRef<Maybe<(value: boolean | PromiseLike<boolean>) => void>>(null);

  const { logIn } = useFacebookLogin({ appId: import.meta.env.VITE_FACEBOOK_APP_ID!, version: '19' } as any);

  const handleError = (error: any) => {
    authenticateResolveCallbackRef.current?.(false);
    console.error(error.message);
    onError?.(error);
  };

  const facebookLogin = async () => {
    try {
      const response = await logIn();
      if (response?.status !== LoginStatus.Connected) {
        handleError({ message: 'Error during Facebook authentication attempt.' })
        authenticateResolveCallbackRef.current?.(false);
        return;
      } else {
        onSuccess(response);
        authenticateResolveCallbackRef.current?.(true);
      }
    } catch (error: any) {
      handleError(error);
    }
  };

  const authenticateWithFacebook = async (): Promise<boolean> => {
    const resultPromise = new Promise<boolean>((resolve) => {
      authenticateResolveCallbackRef.current = resolve;
    });
    await facebookLogin();
    return resultPromise;
  };

  return { authenticateWithFacebook };
};
