import { ComponentType } from 'react';
import { LiveChatProvider } from '@features/contact-live-support';

export const withLiveChat = <T extends object>
  (WrappedComponent: ComponentType<T>) =>
  (props: T) => {
    return (
      <LiveChatProvider
        options={{
          propertyId: import.meta.env.VITE_PROPERTY_ID || '',
          widgetId: import.meta.env.VITE_WIDGET_ID || '',
          apiKey: import.meta.env.VITE_CHAT_API_KEY || ''
        }}>
        <WrappedComponent {...props} />
      </LiveChatProvider>
    );
  };
