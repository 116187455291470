import { FC } from 'react';
import { useDispatch } from 'react-redux';
import compose from 'compose-function';
import { Layout } from '@shared/ui';
import {
  useGetPageLayoutQuery
} from '@entities/page-layout';
import {
  withKycVerification
} from '@features/begin-kyc-verification';
import {
  ToggleCoinsWithoutBalance
} from '@features/toggle-coins';
import {
  SidebarMenu,
  SidebarMenuButton,
  closeSidebarMenu
} from '@widgets/sidebar-menu';
import {
  NotificationsSidebar,
  NotificationsSidebarButton
} from '@widgets/notifications-sidebar';
import {
  LayoutHeader
} from '@widgets/layout-header';
import {
  selectIsFullScreen,
  usePlayGamePageSelector
} from '@pages/play-game';
import {
  withMessagesModal
} from './with-messages-modal';
import {
  withNotifications
} from './with-notifications';
import {
  withLiveChat
} from './with-live-chat';
import {
  withCheckTrackingCodes
} from './with-check-tracking-codes';
import {
  withHotjar
} from './with-hotjar';
import {
  withModals
} from './with-modals';
import {
  withGoogleAuth
} from './with-google-auth';
import {
  withGameCategories
} from './with-game-categories';

export const GameLayout: FC = compose(
  withHotjar,
  withGoogleAuth,
  withCheckTrackingCodes,
  withLiveChat,
  withModals,
  withGameCategories,
  withNotifications,
  withMessagesModal,
  withKycVerification
)(() => {
  const dispatch = useDispatch();
  const { isLoading } = useGetPageLayoutQuery();
  const isFullScreen = usePlayGamePageSelector(selectIsFullScreen);

  const handleCloseNotificationsSidebar = () => {
    dispatch(closeSidebarMenu());
  };

  return isLoading ? null : (
    <Layout
      disableScroll
      headerSlot={
        isFullScreen ? null : (
          <LayoutHeader
            sidebarMenuSlot={
              <SidebarMenu
                notificationsSidebarButtonSlot={
                  <NotificationsSidebarButton
                    onClick={handleCloseNotificationsSidebar}
                  />
                }
              />
            }
            sidebarMenuButtonSlot={<SidebarMenuButton />}
            gameToggleCoinsSlot={<ToggleCoinsWithoutBalance />}
            notificationsSidebarSlot={<NotificationsSidebar />}
            notificationsSidebarButtonSlot={
              <NotificationsSidebarButton
                onClick={handleCloseNotificationsSidebar}
              />
            }
          />
        )
      }
    />
  );
});