import { FC } from 'react';
import { Stack } from '@mui/joy';

export const FreeCoinLabel: FC = () => (
  <Stack
    component='span'
    alignItems='center'
    justifyContent='center'
    sx={({ palette }) => ({
      height: 26,
      paddingInline: 1.5,
      borderRadius: 8,
      background: palette.common[475],
      boxShadow: '0px 0px 8px 0px rgba(18, 197, 126, 0.20)',
      fontSize: 14,
      fontWeight: 700,
      margin: 0,
      textTransform: 'uppercase',
      color: palette.common[700]
    })}>
    free sc
  </Stack>
);