import { ComponentType } from 'react';
import { GtmProvider } from '@features/collect-analytics';

export const withGtm = <P extends object>(
  WrappedComponent: ComponentType<P>
) => (props: P) => (
  <GtmProvider gtmId={import.meta.env.VITE_GTM_ID ?? ''}>
    <WrappedComponent {...props} />
  </GtmProvider>
);
