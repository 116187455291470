import { FC, useCallback, useEffect } from 'react'
import { Box, Modal, ModalDialog, Stack, Typography, useTheme } from '@mui/joy';
import { LockOutlined } from '@mui/icons-material';
import { CloseIcon, IconButton } from '@shared/ui';
import { useModalQueryParam, useQueryParamModalVisibility } from '@shared/lib';
import { selectEmail, selectMobile, useResetPasswordSelector } from '../model';
import { ResetPasswordModalsQueries } from '../api';
import { ConfirmForm } from './confirm-form.component';

export type ConfirmResetPasswordModalProps = {
  open: boolean;
  onClose: () => void;
};

export const ConfirmResetPasswordModal: FC<ConfirmResetPasswordModalProps> = ({
  open,
  onClose
}) => {
  const theme = useTheme();
  const { closeModal } = useModalQueryParam();

  const email = useResetPasswordSelector(selectEmail);
  const mobile = useResetPasswordSelector(selectMobile);
  const isConfirmResetPasswordModal = useQueryParamModalVisibility(
    ResetPasswordModalsQueries.ConfirmResetPassword
  );

  const closeIfNotComplete = useCallback(() => {
    if (!email && !mobile && isConfirmResetPasswordModal) {
      closeModal(ResetPasswordModalsQueries.ConfirmResetPassword)
    }
  }, [closeModal, email, mobile, isConfirmResetPasswordModal])

  useEffect(() => {
    closeIfNotComplete()
  }, [closeIfNotComplete])

  return (
    <Modal
      open={open}
      onClose={onClose}>
      <ModalDialog
        sx={({ breakpoints }) => ({
          width: '100%',
          maxWidth: '358px',
          overflow: 'hidden',
          [breakpoints.down(490)]: {
            maxWidth: 'calc(100vw - 2rem)'
          },
        })}>
        <Stack sx={{
          padding: '1.5rem',
        }}>
          <Stack mb='1rem'>
            <Stack
              direction='row'
              justifyContent='space-between'
              alignItems='center'
              mb='0.75rem'>
              <Stack direction='row'
                gap='0.5rem'
                alignItems='center'>
                <Box
                  sx={({ palette }) => ({
                    borderRadius: '50%',
                    display: 'grid',
                    placeItems: 'center',
                    padding: '0.5625rem',
                    background: palette.common.black,
                  })}>
                  <LockOutlined
                    sx={{
                      fontSize: '0.875rem',
                      color: theme.palette.common[300]
                    }}
                  />
                </Box>
                <Typography sx={({ palette }) => ({
                  color: palette.common.white,
                  fontSize: '1.125rem',
                  fontWeight: 600,
                  lineHeight: 'normal',
                })}>
                  Confirm Reset Password
                </Typography>
              </Stack>
              <IconButton onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </Stack>
            <Typography sx={({ palette }) => ({
              color: palette.common[150],
              fontSize: '0.875rem',
              fontWeight: 400,
              lineHeight: '1.25rem',
            })}>
              Please enter the code received on your email or phone number in order to reset your password.
            </Typography>
          </Stack>
          <ConfirmForm />
        </Stack>
      </ModalDialog>
    </Modal>
  );
};