import {
  FC,
  useEffect,
  useState
} from 'react';
import SumsubWebSdk from '@sumsub/websdk-react';
import {
  Modal,
  ModalProps,
  ModalDialog,
  ModalDialogProps,
  Stack
} from '@mui/joy';
import {
  useTheme,
  useMediaQuery
} from '@mui/material';
import {
  CloseIcon,
  IconButton
} from '@shared/ui';

export type BeginKycVerificationModalProps = Pick<ModalProps, 'open' | 'onClose'> & {
  open: boolean;
  partyId: string;
  accessToken: string;
  onClose?(): void;
  onError?(data: any): void;
  onCompleted?(): void;
  expirationHandler?(): Promise<string>;
  modalDialogProps?: ModalDialogProps;
};

export const BeginKycVerificationModal: FC<BeginKycVerificationModalProps> = ({
  open,
  onClose,
  onError,
  onCompleted,
  partyId,
  accessToken,
  modalDialogProps,
  expirationHandler,
  ...modalProps
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(490));
  const [sdkLoaded, setSdkLoaded] = useState(false);

  const handleMessage = (message: string, data: object): void => {
    switch (message) {
      default: break;
      case 'idCheck.onInitialized':
        setTimeout(() => { setSdkLoaded(true); }, 100);
        break;
      case 'idCheck.onApplicantSubmitted':
        onCompleted?.();
        break;
      case 'idCheck.onError':
        onError?.(data);
        break;
    }
  };

  useEffect(() => {
    if (!open) setSdkLoaded(false);
  }, [open]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      {...modalProps}>
      <ModalDialog
        layout={isMobile ? 'fullscreen' : 'center'}
        sx={({ breakpoints, palette }) => ({
          width: '100%',
          maxWidth: 488,
          maxHeight: 878,
          backgroundColor: palette.common[925],
          [breakpoints.down(491)]: {
            maxHeight: '100%',
            borderRadius: 0,
            boxShadow: 'none',
          },
        })}
        {...modalDialogProps}>
        <Stack
          flexDirection='column'
          sx={({ breakpoints }) => ({
            width: '100%',
            height: '100%',
            padding: 3,
            [breakpoints.down(490)]: {
              paddingInline: 2,
              paddingTop: 2,
              paddingBottom: 4,
            },
            [breakpoints.down(390)]: {
              paddingInline: 1,
              paddingBottom: 2,
            },
          })}>
          <Stack
            direction='row'
            alignItems='center'
            justifyContent='flex-end'
            width='100%'>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Stack>
          <Stack
            sx={{
              minHeight: 221,
              overflow: sdkLoaded ? 'visible' : 'hidden',
              maxHeight: sdkLoaded ? 'none' : 221,
              transition: 'max-height 0.3s ease-out',
            }}>
            <SumsubWebSdk
              accessToken={accessToken}
              expirationHandler={expirationHandler}
              onMessage={handleMessage}
              onError={onError}
            />
          </Stack>
        </Stack>
      </ModalDialog>
    </Modal>
  );
};
