import { FC } from 'react';
import {
  Modal, ModalProps, ModalDialog,
  ModalDialogProps, Stack, Typography, Sheet
} from '@mui/joy';
import { CheckCircleIcon, CloseIcon, IconButton } from '@shared/ui';

export type YourProfileVerifiedModalProps = Pick<ModalProps, 'open' | 'onClose'> & {
  open: boolean;
  onClose?(): void;
  modalDialogProps?: ModalDialogProps;
};

export const YourProfileVerifiedModal: FC<YourProfileVerifiedModalProps> = ({
  open,
  onClose,
  modalDialogProps,
  ...props
}) => (
  <Modal
    open={open}
    onClose={onClose}
    {...props}>
    <ModalDialog
      layout='center'
      {...modalDialogProps}>
      <Stack
        direction='column'
        justifyContent='flex-start'
        alignItems='center'
        gap={2}
        sx={{
          maxHeight: 384,
          maxWidth: 358,
          paddingBlock: 3,
          paddingInline: 3
        }}>
        <Stack
          direction='row'
          justifyContent='space-between'
          alignItems='center'
          width='100%'>
          <Stack
            direction='row'
            justifyContent='space-between'
            alignItems='center'
            gap={1}>
            <Sheet sx={({ colorSchemes }) => ({
              background: colorSchemes.dark.palette.common[700],
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: '45%',
              height: 32,
              width: 32
            })}>
              <CheckCircleIcon
                sx={({ colorSchemes }) => ({
                  fontSize: 16,
                  color: colorSchemes.dark.palette.common[700]
                })}
              />
            </Sheet>
            <Typography
              sx={({ colorSchemes }) => ({
                color: colorSchemes.dark.palette.common[700],
                fontSize: 20,
                fontStyle: 'normal',
                fontWeight: 600,
                lineHeight: '150%',
              })}>
              Verification Successful!
            </Typography>
          </Stack>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Stack>
        <Typography sx={({ colorSchemes }) => ({
          color: colorSchemes.dark.palette.common[150],
          textAlign: 'left',
          fontSize: 16,
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: '150%'
        })}>
          Your KYC verification is now successfully completed.
        </Typography>
      </Stack>
    </ModalDialog>
  </Modal>
);