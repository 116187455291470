import { FC } from 'react'
import { Sheet } from '@mui/joy';
import { featureToggles } from '@shared/config';
import { ChangePasswordCard } from '@features/change-password';
import { VerifyEmailCard } from '@features/verify-email';
import { VerifyPhoneCard } from '@features/verify-phone/ui';
import { ManagePromotionalSubscriptionsCard } from '@features/manage-promotional-subscriptions';

export const SettingTab: FC = () => (
  <Sheet
    sx={({ breakpoints }) => ({
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gap: 3,
      width: '100%',
      [breakpoints.down(1129)]: {
        gridTemplateColumns: '100%',
      },
    })}>
    <VerifyEmailCard
      inputProps={{
        root: {
          sx: ({ breakpoints }) => ({
            height: 196,
            maxWidth: 426.5,
            width: '100%',
            [breakpoints.down(1129)]: {
              maxWidth: '100%',
              gridColumnStart: 1,
              gridColumnEnd: 2,
            }
          })
        }
      }} />
    {featureToggles.verifyPhone && (
      <VerifyPhoneCard
        inputProps={{
          root: {
            sx: ({ breakpoints }) => ({
              height: 196,
              maxWidth: 426.5,
              width: '100%',
              [breakpoints.down(1129)]: {
                maxWidth: '100%',
                gridColumnStart: 1,
                gridColumnEnd: 2,
              }
            })
          }
        }} />
    )}
    <ChangePasswordCard
      inputProps={{
        root: {
          sx: ({ breakpoints }) => ({
            height: 196,
            maxWidth: 426.5,
            width: '100%',
            [breakpoints.down(1129)]: {
              maxWidth: '100%',
              gridColumnStart: 1,
              gridColumnEnd: 2,
            }
          })
        }
      }} />
    <ManagePromotionalSubscriptionsCard
      inputProps={{
        root: {
          sx: ({ breakpoints }) => ({
            height: 196,
            maxWidth: 426.5,
            width: '100%',
            [breakpoints.down(1129)]: {
              maxWidth: '100%',
              gridColumnStart: 1,
              gridColumnEnd: 2,
            }
          })
        }
      }} />
  </Sheet>
);