import { FC, MouseEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Modal, ModalDialog, ModalDialogProps, Stack, Typography, Box } from '@mui/joy';
import { CloseIcon, IconButton } from '@shared/ui';
import { isExternalUrl } from '@shared/lib';
import { ChannelMessage } from '@entities/messages';

export type MessageModalProps = Pick<ModalDialogProps, 'sx'> & {
  open: boolean;
  message: Maybe<ChannelMessage>;
  onClose(): void;
};

export const MessageModal: FC<MessageModalProps> = ({
  open,
  message,
  onClose,
  ...props
}) => {
  const navigate = useNavigate();

  const handleClose = (
    event: MouseEvent<HTMLButtonElement>,
    reason: 'backdropClick' | 'escapeKeyDown' | 'closeClick'
  ): void => {
    event?.stopPropagation();
    event?.preventDefault();
    if (reason === 'closeClick') onClose();
  };

  const handleButtonClick = (): void => {
    onClose();
    if (isExternalUrl(message?.buttonLink ?? '')) {
      window.open(message?.buttonLink ?? '', '_blank');
    } else {
      navigate(message?.buttonLink ?? '');
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}>
      <ModalDialog
        layout='center'
        sx={{ width: 39.75 }}
        {...props}>
        <Stack
          sx={{
            width: '100%',
            height: '100%',
            padding: 3,
            overflowY: 'auto',
          }}>
          <Stack
            direction='row'
            justifyContent='space-between'
            width='100%'
            mb={1.5}>
            <Typography level='h3' fontSize={18}>
              {message?.title}
            </Typography>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Stack>
          <Stack
            sx={{
              gap: 1.5,
              width: '100%',
              overflowY: 'auto',
            }}>
            {message?.imgUrl && (
              <Box
                component='img'
                loading='lazy'
                src={message?.imgUrl}
                sx={{
                  borderRadius: '0.5rem',
                  width: '100%',
                }}
              />
            )}
            {message?.text?.map((messageItem, index) => (
              <Typography key={index}>
                {messageItem}
              </Typography>
            ))}
            {message?.buttonText && (
              <Button
                color={
                  message?.isButtonGreen
                    ? 'success'
                    : 'primary'
                }
                onClick={handleButtonClick}>
                {message?.buttonText}
              </Button>
            )}
          </Stack>
        </Stack>
      </ModalDialog>
    </Modal>
  );
};
