import {
  ComponentType,
  Fragment,
  useEffect,
} from 'react';
import {
  useDispatch
} from 'react-redux';
import {
  Typography
} from '@mui/joy';
import {
  useToaster
} from '@shared/ui';
import {
  useQueryParamModalVisibility,
  useModalQueryParam
} from '@shared/lib';
import {
  featureToggles
} from '@shared/config';
import {
  WorldPayCheckoutFailedPopup,
  WorldPayCheckoutSuccessPopup,
  closeWorldPayCheckoutFailedPopup,
  openWorldPayCheckoutFailedPopup,
  closeWorldPayCheckoutSuccessPopup,
  openWorldPayCheckoutSuccessPopup,
  selectActionPurpose,
  usePaymentsSelector
} from '@entities/payments';
import {
  selectOpenCheckoutModal,
  useCoinPackagesSelector
} from '@entities/coin-packages';
import {
  useLazyGoogleAds,
  useSendDataToGtm
} from '@features/collect-analytics';
import {
  CoinPackagesModal,
  WorldPayCheckoutModal,
  PaymentIQCashierCheckoutModal,
  PleaseConfirmYourIdentityModal,
  selectBuyCoinsRequirements,
  useBuyCoinsSelector
} from '@features/buy-coins';

export const withBuyModal = <T extends object>
  (WrappedComponent: ComponentType<T>) =>
  (props: T) => {
    const dispatch = useDispatch();
    const { error } = useToaster();
    const { closeModal, openModal, toggleModal } = useModalQueryParam();
    const { sendDataToGtm } = useSendDataToGtm();
    const { trackConversion } = useLazyGoogleAds();

    const openPayCheckoutModal = useCoinPackagesSelector(selectOpenCheckoutModal);
    const isBuyActionPurpose = usePaymentsSelector(selectActionPurpose('buy'));

    const { buyingAllowed, accessRestricted, kycRequired } = useBuyCoinsSelector(selectBuyCoinsRequirements(
      useQueryParamModalVisibility('buy')
    ));

    useEffect(() => {
      if (kycRequired) {
        toggleModal('buy', 'kyc-verify');
      }
    }, [kycRequired, error, toggleModal]);

    const handleClose = (): void => {
      closeModal('buy');
    };

    const handleDepositStart = (): void => {
      sendDataToGtm({
        dataLayer: {
          event: 'purchase_start',
        }
      })
    };

    const handleDepositSuccess = (
      depositResult?: {
        amount: string;
        cardNumber?: string;
        referenceId?: string;
      }
    ): void => {
      if (depositResult) {
        dispatch(openWorldPayCheckoutSuccessPopup({
          actionPurpose: 'buy',
          result: depositResult
        }));
      }
      sendDataToGtm({
        dataLayer: {
          event: 'purchase_success',
          depositAmount: depositResult?.amount,
          depositCurrency: 'USD',
          depositcount: '1',
          paymentMethod: 'WorldPay'
        }
      });
      trackConversion({ adsLabel: 'iOjyCMyZoeUZEIrhv9g9' });
    };

    const handleDepositFail = (
      depositResult?: {
        amount: string;
        cardNumber?: string;
        referenceId?: string;
      }
    ): void => {
      if (depositResult) {
        dispatch(openWorldPayCheckoutFailedPopup({
          actionPurpose: 'buy',
          result: depositResult
        }));
      }
      sendDataToGtm({
        dataLayer: {
          event: 'purchase_fail',
          paymentMethod: 'Trustly'
        }
      });
    };

    const handleCloseWorldPayCheckoutSuccessPopup = (): void => {
      dispatch(closeWorldPayCheckoutSuccessPopup());
    };

    const handleCloseWorldPayCheckoutFailedPopup = (): void => {
      dispatch(closeWorldPayCheckoutFailedPopup());
    };

    const handleWorldPayCheckoutTryAgain = (): void => {
      dispatch(closeWorldPayCheckoutFailedPopup());
      openModal('buy');
    };

    return (
      <Fragment>
        <WrappedComponent {...props} />

        {buyingAllowed ? (
          <CoinPackagesModal
            open
            onClose={handleClose}
            titleSlot={(
              <Typography
                level='h2'
                fontSize='1.25rem'>
                Coin packages
              </Typography>
            )}
          />
        ) : null}

        <PleaseConfirmYourIdentityModal
          open={accessRestricted}
          onClose={handleClose}
          titleSlot={(
            <Typography
              level='h2'
              fontSize='1.25rem'>
              Coin packages
            </Typography>
          )}
        />

        {featureToggles.paymentIQCashier && openPayCheckoutModal && (
          <PaymentIQCashierCheckoutModal
            onDepositFail={handleDepositFail}
            onDepositStart={handleDepositStart}
            onDepositSuccess={handleDepositSuccess}
          />
        )}

        {!featureToggles.paymentIQCashier && (
          <Fragment>
            {openPayCheckoutModal && (
              <WorldPayCheckoutModal
                onDepositFail={handleDepositFail}
                onDepositStart={handleDepositStart}
                onDepositSuccess={handleDepositSuccess}
              />
            )}
            {isBuyActionPurpose && (
              <Fragment>
                <WorldPayCheckoutSuccessPopup
                  onClose={handleCloseWorldPayCheckoutSuccessPopup}
                />
                <WorldPayCheckoutFailedPopup
                  onClose={handleCloseWorldPayCheckoutFailedPopup}
                  onTryAgain={handleWorldPayCheckoutTryAgain}
                />
              </Fragment>
            )}
          </Fragment>
        )}
      </Fragment>
    );
  };