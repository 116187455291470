import {
  useState,
  forwardRef
} from 'react';
import {
  FormControl,
  FormHelperText,
  FormLabel,
  InputProps,
  Input as JoyInput,
  InputProps as JoyInputProps,
} from '@mui/joy';
import {
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon
} from '@mui/icons-material';
import { IconButton } from '@shared/ui';

export interface TextInputProps extends Omit<JoyInputProps, 'error'> {
  label?: string;
  error?: string;
  sx?: InputProps['sx'];
};

export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(({
  label,
  type,
  error,
  color = 'neutral',
  endDecorator,
  ...props
}, ref) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleChangeVisibility = () => {
    setShowPassword((state) => !state);
  };

  return (
    <FormControl
      ref={ref}
      disabled={props.disabled}
      error={!!error}
      sx={[({ palette }) => ({
        minHeight: 90,
        '& .MuiButtonBase-root.Mui-disabled': {
          color: palette.common[515],
        }
      }),
      ...(Array.isArray(props?.sx)
        ? props?.sx
        : [props?.sx])
      ]}>
      {label && (
        <FormLabel sx={{ marginBottom: 0.5 }}>
          {label}
        </FormLabel>
      )}
      <JoyInput
        {...props}
        error={!!error}
        color={color}
        type={type === 'password' && !endDecorator && showPassword
          ? 'text'
          : type}
        endDecorator={
          endDecorator
            ? endDecorator
            : (type === 'password' && (
              <IconButton
                onClick={handleChangeVisibility}
                sx={{ minHeight: '1.5rem' }}>
                {showPassword
                  ? <VisibilityIcon sx={{ fontSize: 24 }} />
                  : <VisibilityOffIcon sx={{ fontSize: 24 }} />}
              </IconButton>
            ))
        }
      />
      {error && (
        <FormHelperText sx={{ marginTop: 0.5 }}>
          {error}
        </FormHelperText>
      )}
    </FormControl>
  );
});
