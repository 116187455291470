import { FC } from 'react';
import compose from 'compose-function';
import {
  useDispatch
} from 'react-redux';
import {
  Layout
} from '@shared/ui';
import {
  useGetPageLayoutQuery
} from '@entities/page-layout';
import {
  withKycVerification
} from '@features/begin-kyc-verification';
import {
  ToggleCoins
} from '@features/toggle-coins';
import {
  SidebarMenu,
  SidebarMenuButton,
  StickyNavigation,
  closeSidebarMenu
} from '@widgets/sidebar-menu';
import {
  NotificationsSidebar,
  NotificationsSidebarButton
} from '@widgets/notifications-sidebar';
import {
  LayoutHeader
} from '@widgets/layout-header';
import {
  LayoutFooter
} from '@widgets/layout-footer';
import {
  FollowUsBlock
} from '@widgets/follow-us-block';
import {
  withHotjar
} from './with-hotjar';
import {
  withNotifications
} from './with-notifications';
import {
  withGoogleAuth
} from './with-google-auth';
import {
  withGameCategories
} from './with-game-categories';
import {
  withCheckTrackingCodes
} from './with-check-tracking-codes';
import {
  withLiveChat
} from './with-live-chat';
import {
  withModals
} from './with-modals';


export const BaseLayout: FC = compose(
  withHotjar,
  withGoogleAuth,
  withCheckTrackingCodes,
  withLiveChat,
  withModals,
  withGameCategories,
  withNotifications,
  withKycVerification
)(() => {
  const dispatch = useDispatch();
  const { isLoading } = useGetPageLayoutQuery();

  const handleCloseNotificationsSidebar = () => {
    dispatch(closeSidebarMenu());
  };

  return isLoading ? null : (
    <Layout
      headerSlot={
        <LayoutHeader
          toggleCoinsSlot={<ToggleCoins />}
          sidebarMenuSlot={
            <SidebarMenu
              notificationsSidebarButtonSlot={
                <NotificationsSidebarButton
                  onClick={handleCloseNotificationsSidebar}
                />
              }
            />
          }
          sidebarMenuButtonSlot={<SidebarMenuButton />}
          notificationsSidebarSlot={<NotificationsSidebar />}
          notificationsSidebarButtonSlot={
            <NotificationsSidebarButton
              onClick={handleCloseNotificationsSidebar}
            />
          }
        />
      }
      footerSlot={
        <LayoutFooter
          followUsSlot={<FollowUsBlock />}
        />
      }
      stickyNavigationSlot={<StickyNavigation />}
    />
  );
});