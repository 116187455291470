import { useEffect, useState } from 'react';
import { ComponentType } from 'react';
import { hotjar } from 'react-hotjar';

export const withHotjar = <P extends object>(WrappedComponent: ComponentType<P>) => {
  const WithHotjar = (props: P) => {
    const [isHotjarInitialized, setHotjarInitialized] = useState(false);

    useEffect(() => {
      const initializeHotjar = () => {
        hotjar.initialize({
          id: import.meta.env?.VITE_HJID ? Number(import.meta.env?.VITE_HJID) : 0,
          sv: import.meta.env?.VITE_HJSV ? Number(import.meta.env?.VITE_HJSV) : 0
          }
        );
        setHotjarInitialized(true);
        window.removeEventListener('click', initializeHotjar);
        window.removeEventListener('scroll', initializeHotjar);
      };

      if (!isHotjarInitialized) {
        window.addEventListener('click', initializeHotjar);
        window.addEventListener('scroll', initializeHotjar);
      }

      return () => {
        window.removeEventListener('click', initializeHotjar);
        window.removeEventListener('scroll', initializeHotjar);
      };
    }, [isHotjarInitialized]);

    return <WrappedComponent {...props} />;
  };

  return WithHotjar;
};
